import React, { Fragment, useState } from 'react'
import { Link } from "gatsby"
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/react/outline'

const MobileDrawerUpdate = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="flex justify-end xl:hidden">
        <button
          className="text-black focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-10 w-10 text-black" aria-hidden="true" />
        </button>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 overflow-hidden z-40" open={open} onClose={setOpen}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                      <button
                        className="text-white focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <nav className="flex flex-col mt-4 px-8">
                      <div className="mt-2 mb-4">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between w-full text-base text-gray-900 tracking-wider">
                                <span>IT Services</span>
                                <ChevronDownIcon
                                  className={`${
                                    open ? 'transform rotate-180' : ''
                                  } w-6 h-6 text-gray-900`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/secure-remote-access"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Secure Remote Access
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/managed-it-services"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Fully Managed IT Solutions
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/server-workstation-setup-services"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Infrastructure Setup and Maintenance
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/hosted-exchange-services"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Hosted Exchange / Microsoft Exchange
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/firewall-router-setup-services"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      IT Security / Cyber Security
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/cloud-backup-services"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Online Cloud Backup
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/voip-design-implementation-services"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      VOIP Design and Implementation
                                    </Link>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>

                      <div className="mt-2 mb-4">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between w-full text-base text-gray-900 tracking-wider">
                                <span>Company</span>
                                <ChevronDownIcon
                                  className={`${
                                    open ? 'transform rotate-180' : ''
                                  } w-6 h-6 text-gray-900`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/team"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Meet Your Team
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <Link
                                      to="/testimonials"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Testimonials
                                    </Link>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <a
                                      href="https://itproactive.bamboohr.com/jobs/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Career
                                    </a>
                                  </div>
                                </div>
                                <div className="px-2 py-2">
                                  <div>
                                    <a
                                      href="https://blog.itproactive.com/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className='text-base text-gray-900 tracking-wider'
                                    >
                                      Tech Tips
                                    </a>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>

                      <div className="mt-2 mb-4">
                        <Link to="/contact">
                          <button
                            className="text-base text-gray-900 tracking-wider"
                          >
                            Contact
                          </button>
                        </Link>
                      </div>

                      <div className="flex items-center justify-between mt-6 mb-6">
                        <a href="https://ww5.autotask.net/ClientPortal/login.aspx?ci=505040" target="_blank" rel="noopener noreferrer" className="text-base text-site-lightblue tracking-wider font-semibold mr-6 uppercase">
                          Support
                        </a>
                        <a href="tel:949-543-0230" target="_blank" rel="noopener noreferrer" className="w-full bg-red-600 hover:bg-red-700 text-white text-center font-display px-4 py-2 font-semibold uppercase tracking-wider rounded">
                        (949) 543-0230
                        </a>
                      </div>

                      <div className="flex items-center justify-between mt-6 mb-6">
                        <Link to="assessment">
                          <p className="text-base text-site-lightblue tracking-wider font-semibold mr-6 uppercase">
                            Sales
                          </p>
                        </Link>
                        <a href="tel:9495430234" className="w-full bg-red-600 hover:bg-red-700 text-white text-center font-display px-4 py-2 font-semibold uppercase tracking-wider rounded">
                          (949) 543-0234
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default MobileDrawerUpdate