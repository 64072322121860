import * as React from "react"
import { Link } from 'gatsby'

const FooterNewUpdate = () => (
  <footer className='bg-gray-800 font-body text-white pt-24 pb-12'>
    <div className='w-11/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 mx-auto'>
      <div>
        <h2 className="text-4xl font-bold mb-3">IT Proactive</h2>
        <p className="mb-3">1314 W 5th Street, 2nd Floor<br/> Santa Ana, CA 92703</p>
        <a href="tel:9495430234" className="w-full bg-red-600 hover:bg-red-700 text-white font-display px-6 py-2 font-semibold uppercase tracking-wider rounded">
          (949) 543-0234
        </a>
      </div>

      <div className="md:col-span-1 lg:col-span-2">
        <h3 className="text-2xl font-semibold mb-5">IT Services</h3>
        <ul>
          <Link to="/secure-remote-access">
            <li className="text-gray-300 hover:text-white mb-2">Secure Remote Access</li>
          </Link>
          <Link to="/managed-it-services">
            <li className="text-gray-300 hover:text-white mb-2">Fully Managed IT Solutions</li>
          </Link>
          <Link to="/server-workstation-setup-services">
            <li className="text-gray-300  hover:text-white mb-2">Infrastructure Setup and Maintenance</li>
          </Link>
          <Link to="/hosted-exchange-services">
            <li className="text-gray-300  hover:text-white mb-2">Hosted Exchange / Microsoft Exchange</li>
          </Link>
          <Link to="/firewall-router-setup-services">
            <li className="text-gray-300  hover:text-white mb-2">IT Security / Cyber Security</li>
          </Link>
          <Link to="/cloud-backup-services">
            <li className="text-gray-300  hover:text-white mb-2">Online Cloud Backup</li>
          </Link>
          <Link to="/voip-design-implementation-services">
            <li className="text-gray-300  hover:text-white mb-2">VOIP Design and Implementation</li>
          </Link>
        </ul>
      </div>

      <div>
        <h3 className="text-2xl font-semibold mb-5">Company</h3>
        <ul>
          <Link to="/team">
            <li className="text-gray-300  hover:text-white mb-2">Meet Your Team</li>
          </Link>
          <Link to="/testimonials">
            <li className="text-gray-300  hover:text-white mb-2">Testimonials</li>
          </Link>
          <Link to="/areas">
            <li className="text-gray-300  hover:text-white mb-2">Areas We Serve</li>
          </Link>
          <a
            href="https://itproactive.bamboohr.com/jobs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="text-gray-300  hover:text-white mb-2">Career</li>
          </a>
          <a
            href="https://blog.itproactive.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="text-gray-300  hover:text-white mb-2">Tech Tips</li>
          </a>
          <Link to="/contact">
            <li className="text-gray-300  hover:text-white mb-2">Contact Us</li>
          </Link>
          <a href="https://ww5.autotask.net/ClientPortal/login.aspx?ci=505040" target="_blank" rel="noopener noreferrer">
            <li className="text-gray-300  hover:text-white mb-2">Support</li>
          </a>
          <Link to="/privacy-policy">
            <li className="text-gray-300  hover:text-white mb-2">Privacy Policy</li>
          </Link>
          <Link to="/terms">
            <li className="text-gray-300  hover:text-white mb-2">Terms of Service</li>
          </Link>
        </ul>
      </div>
      
      <div>
        <h3 className="text-2xl font-semibold mb-5">Follow Us</h3>
        <ul>
          <a href="https://www.facebook.com/itproactive/" target="_blank" rel="noopener noreferrer">
            <li className="text-gray-300  hover:text-white mb-2">Facebook</li>
          </a>
          <a href="https://twitter.com/ITProactive1" target="_blank" rel="noopener noreferrer">
            <li className="text-gray-300  hover:text-white mb-2">Twitter</li>
          </a>
          <a href="https://www.linkedin.com/company/10693226/" target="_blank" rel="noopener noreferrer">
            <li className="text-gray-300  hover:text-white mb-2">LinkedIn</li>
          </a>
          <a href="https://www.youtube.com/channel/UChP1mAQ_OQaq4aeXLVpxGzQ" target="_blank" rel="noopener noreferrer">
            <li className="text-gray-300  hover:text-white mb-2">Youtube</li>
          </a>
        </ul>
      </div>
    </div>

    <div className='w-11/12 max-w-screen-xl mx-auto mt-24'>
      <p className='text-sm text-gray-600 mb-6 text-center'>© {new Date().getFullYear()} IT Proactive. Website by <a href="https://www.fastweb.dev" target="_blank" rel="noopener noreferrer" className="hover:text-white">fastweb.dev</a></p>
    </div>
  </footer>
)

export default FooterNewUpdate